<template>
  <div class="d-flex flex-column">
    <AppPageCard>
      <template #card-title>
        {{ $t("SIDEBAR.ATTENDANCE") }}
      </template>

      <template #card-toolbar>
        <!-- begin: b-nav tabs  -->
        <b-nav pills class="nav-light-primary font-weight-bolder">
          <b-nav-item to="daily" exact exact-active-class="active"
            >{{ $t("ATTENDANCE.DAILY") }}
          </b-nav-item>
          <b-nav-item to="monthly" exact exact-active-class="active"
            >{{ $t("ATTENDANCE.MONTHLY") }}
          </b-nav-item>
        </b-nav>
        <!-- end: b-nav tabs  -->
      </template>
      <router-view></router-view>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "PersonalAttendancePage",
  components: {
    AppPageCard,
  },
  data: function () {
    return {
      tabIndex: 0,
    };
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style scoped></style>
